import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import '../../../../static/styles/fontawesome-5.15.2/css/fontawesome.min.css'
import MotieTable from '../../../components/MotieTable'
import { useIntl } from '@intractive/gatsby-plugin-react-intl'
import * as style from "./index.module.css"

function Successen(props) {
    const successen = props.successen;
    const classes = props.classes;
    return successen.map(function(edge) {
        const node = edge.node;
        return (
            <div className={classes}>
                <h3>{node.name}</h3>
                <ul>
                    {node.successen.map(function(s) {
                        return (
                            <div className={[style.flex].join(" ")}>
                                <div className={[style.icon, "medium-2 small-2"].join(" ")}>
                                    <img src={node.iconUrl} />
                                </div>
                                <div className="medium-10 small-10">
                                    <li className={style.text}>{s.text}</li>
                                </div>
                            </div>
                        );
                    })}
                </ul>
            </div>
        );
    })
}

const PageMoties = ({ data }) => {
  const intl = useIntl(); 
  return (

    <Layout>
      <Helmet>
        <title>{intl.formatMessage({id: "Onze successen"})} · STIP</title>
      </Helmet>
      <div className="row main-content" style={{float: 'inherit'}}>
        <div className="large-12 medium-12 small-12 columns">
          <h2>{intl.formatMessage({id: "Successen fractie"})}</h2>
            {intl.formatMessage({id:"Hieronder staat een overzicht met wat STIP de afgelopen jaren voor jou heeft bereikt en waar we erg trots op zijn!"})}
            <div className={style.flex_fractie}>
                <Successen classes={style.success_fractie} successen={data.successen_fractie.edges} />
            </div>
          <h2>{intl.formatMessage({id: "Successen wethouder"})}</h2>
            {intl.formatMessage({id:"De afgelopen jaren heeft onze wethouder Bas Vollebregt veel bereikt, zoals te lezen in deze lijst. STIP is hier erg trots op."})}
            <div className="row">
                <Successen classes="left large-4 medium-12 small-12 columns" successen={data.successen_wethouder.edges} />
            </div>
          <h2>{intl.formatMessage({id: "Alle successen"})}</h2>
          <p>
            {intl.formatMessage({id: "We hebben nog veel meer bereikt!"})}{" "}
            {intl.formatMessage({id: "In de onderstaande tabel vind je een overzicht van alle ingediende voorstellen van de fractie vanaf maart 2018."})}
            {" "}
            {intl.formatMessage({id: 'Het is mogelijk om te sorteren en filters toe te passen. In de "Type" kolom kan je zien of het gaat om een motie (M), amendement (A), Toezegging (T), Initiatiefvoorstel (I) of Schriftelijke Vragen (SV).'})}
            {intl.formatMessage({id: "Heb je vragen over een voorstel? Stel hem aan het verantwoordelijke fractielid of mail naar"})}{" "}<a href="mailto:fractie@stipdelft.nl">fractie@stipdelft.nl</a></p>
        </div>
        <div className="columns large-12 medium-12 small-12">
          <br/>
            <MotieTable tabeldata={data.moties.edges}></MotieTable>
        </div>
      </div>
    </Layout>
  )
}

export default PageMoties

export const query = graphql`
  query($node_locale: String) {
    successen_fractie: allContentfulSuccessen(
      filter: {node_locale: {eq: $node_locale}, who: {eq: "Fractie"}}
      sort: {order: ASC, fields: order}
    ) {
      edges {
        node {
          name
          iconUrl
          successen {
            text
          }
        }
      }
    }
    successen_wethouder: allContentfulSuccessen(
      filter: {node_locale: {eq: $node_locale}, who: {eq: "Wethouder"}}
      sort: {order: ASC, fields: order}
    ) {
      edges {
        node {
          name
          iconUrl
          successen {
            text
          }
        }
      }
    }
    moties: allContentfulMotieAmendementOfToezegging(
      sort: { fields: datumIngediend, order: DESC }
      filter: {node_locale: {eq: $node_locale}}
    )
      {
      edges {
        node {
          datumIngediend
          type
          motietitel
          indieners
          fractielid {
            name
            slug
          }
          portefeuilles {
            titel
            slug
          }
          beschrijving {
            beschrijving
          }
          resultaat
          risLink
        }
      }
    }
  }
`
